import Glide from '@glidejs/glide'
import Tabset from '../lib/Tabset.js';

// home page advantages slider/tabs
if (window.innerWidth < 767) {
    //init slider
    const mobile_sliders = document.querySelectorAll('[data-mobile-glide]');
    if (mobile_sliders.length) {
        mobile_sliders.forEach((slider) => {
            slider.querySelectorAll('.tabs-body').forEach((tab_body) => {
                tab_body.classList.remove('tabs-body', 'open');
                tab_body.classList.add('advantage-slide', 'open');
                delete tab_body.dataset.tabsetBody;
            });
        });

        let glide = new Glide('[data-mobile-glide]', {
            'type': 'carousel',
            'gap': 0,
            'peek': 0
        }).mount()

        glide.on('run.after', function(e) {
            let tabset = document.getElementById('advantage_block')?.querySelector('[data-tabset-current]');
            if(tabset) {
                tabset.dataset.tabsetCurrent = `tab-${glide.index}`;
            }
        });
    }
} else {
    //init tabset
    let tabsets = document.querySelectorAll('[data-desktop-tabset]');
    tabsets.forEach((tabset) => {
        new Tabset(tabset, {
            transition_time: 300
        });
    });
}


