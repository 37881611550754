var page_header = {
    el: document.querySelector('[page-header]'),
    hamburger: document.querySelector('[page-header-hamburger]')
};

if (page_header.el && page_header.hamburger) {
    page_header.hamburger.addEventListener('click', e => {
        e.preventDefault();
        page_header.el.classList.toggle('is-open');
    });
}

window.onload = function(){
    setTimeout(() => {
        console.log("Going to hash if there is one")
        var hash = window.location.hash.substr(1);
        const data = document.getElementById(hash);
        
        if (data) {
            data.scrollIntoView()
        }
       
        
    }, 1500);
}
