import tippy from 'tippy.js';
import {roundArrow} from 'tippy.js';
import Tabset from './lib/Tabset.js';
import './lib/Inview';
import './lib/Slider';
import './components/page-header';

/* FOR USE WITH LEGACY BLOG */
tippy('[data-tippy-content]', {
    animation: "scale-subtle",
    theme: "yellow",
    arrow: roundArrow
});



