class Tabset {
  constructor(el = null, options = {}) {
      if(!el) {
          return;
      }

      this.tabset_el = el;

      // make sure we have an id for this tabset
      this.tabset_el.id = this.tabset_el.id || `atd-tabset-${Tabset.tabsets.length + 1}`;

      let tab_bodies = this.tabset_el.querySelectorAll(`[data-tabset-body]`);
      this.tab_body_ids = [];
      tab_bodies.forEach((body) => {
          this.tab_body_ids.push(body.dataset.tabsetBody);
      });

      this.options = Object.assign({
          transition_time: 300,
          single_open: true,
          always_one: true,
          onTabOpen: null,
          onTabClosed: null
      }, options);

      this.toggleTabsetListener = (e) => {
          if(e.target.dataset.toggleTabset) {
              e.preventDefault();
              e.stopPropagation();

              if(this.tab_body_ids.indexOf(e.target.dataset.toggleTabset) >= 0) {
                  this.toggleTabset(e.target.dataset.toggleTabset);
              }

              return false;
          }
      }

      this.setEventListeners();

      Tabset.tabsets.push(this);
  }

  toggleTabset(tab_id) {
      let tab_body = this.tabset_el.querySelectorAll(`[data-tabset-body="${tab_id}"]`);
      let tab_toggles = document.querySelectorAll(`[data-toggle-tabset="${tab_id}"]`);

      if(!tab_body) {
          return false;
      }

      tab_body.forEach((body) => {
          if(body.classList.contains('open') && this.options.always_one) {
              return false;
          }

          body.classList.add('transitioning');
      });
      this.tabset_el.classList.add('transitioning');
      this.tabset_el.dataset.tabsetCurrent = tab_id;

      if(this.options.single_open) {
          this.closeAll(tab_body);
      }

      window.setTimeout(() => {
          tab_body.forEach((body) => {
              body.classList.add('open');

              if(typeof this.options.onTabOpen === 'function') {
                  this.options.onTabOpen.call(this, body);
              }
          });

          tab_toggles.forEach((toggle) => {
              toggle.classList.add('open');
          });

          window.setTimeout(() => {
              tab_body.forEach((body) => {
                  body.classList.remove('transitioning');
              });
              this.tabset_el.classList.remove('transitioning');
          }, this.options.transition_time);
      }, 0);
  }

  closeTabset(tab) {
      if(!tab) {
          return false;
      }

      let tab_toggles = document.querySelectorAll(`[data-toggle-tabset="${tab.dataset.tabsetBody}"]`);

      tab.classList.add('transitioning');
      this.tabset_el.classList.add('transitioning');

      window.setTimeout(() => {
          tab.classList.remove('open');
          tab_toggles.forEach((toggle) => {
              toggle.classList.remove('open');
          });

          window.setTimeout(() => {
              tab.classList.remove('transitioning');
              this.tabset_el.classList.remove('transitioning');
          }, this.options.transition_time);
      }, 0);
  }

  closeAll(except) {
      except =  except.length ? except[0] : except;
      let tabs = this.tabset_el.querySelectorAll('[data-tabset-body]');

      tabs.forEach((tab) => {
          if(tab.classList.contains('open') && tab.dataset.tabsetBody !== except.dataset.tabsetBody) {
              this.closeTabset(tab);
          }
      });
  }

  setEventListeners() {
      document.body.addEventListener('click', this.toggleTabsetListener);
  }

  static getTabsetById(id) {
      let match = false;

      Tabset.tabsets.forEach((tabset) => {
          if(!match) {
              match = tabset.tabset_el.id === id ? tabset : false;
          }
      });

      return match;
  }
}

Object.defineProperty(Tabset, 'tabsets', {
  get: function() { return this.hasOwnProperty('_tabsets') ? this._tabsets : void 0; },
  set: function(v) { this._tabsets = v; }
});

Tabset.tabsets = [];

export default Tabset;