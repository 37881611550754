import 'intersection-observer';

let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
}

let setInview = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            console.log('viewed element', entry.target);
            entry.target.dataset.inview = true;
            observer.unobserve(entry.target);
        }
    });
}

let observer = new IntersectionObserver(setInview, options);

let targets = document.querySelectorAll('[data-inview="false"]');

targets.forEach(target => {
    observer.observe(target);
});